<template>
  <div>
    <div class="position-relative overflow-hidden bg-light">
      <div class="col-md-8 mx-auto my-5">
        <h1 class="display-4 font-weight-normal">Sobre nós</h1>
        <p class="lead font-weight-normal">
          <!-- Reveja partidas de CS:GO direto no navegador -->
        </p>
      </div>
    </div>
    <div class="container p-lg-5">
      <div class="row">
        <div class="col-md-8 col-12">
          <p class="lead">Estamos aqui para ajudar você e seu time a melhorarem no CS:GO. Reveja partidas direto no navegador, sem precisar baixar nada.</p>

          <!-- <h2>Ligações a Sites de terceiros</h2>
          <p>O FIGHTBACK.club possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo.</p>
          <p>Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.</p> -->
        </div>
        <div class="col-md-4 col-12">
          <h3>Fale conosco</h3>

          <p>Nos mande um email, contato@fightback.club</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  metaInfo() {
    return {
      title: "Sobre o FIGHTBACK"
    };
  }
};
</script>
